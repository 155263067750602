.data-stack,
.data-stack-ul {
    display: flex;
    align-items: center;
}

.data-stack-ul {
    margin-top: 8px !important;
}

.data-span {
    margin: 0 5px;
    width: 250px;
}

.data-heading {
    padding: 10px 0;
}

.data-span2 {
    width: 224px;
}

.ul-data-2 {
    color: #1790bc;
    padding: 0 20px;
}

/* NOTIFICATION CSS */
/* style={{ justifyContent: "flex-start", marginBottom: "40px" }} */
.notification row-1 {
    justify-content: flex-start;
    margin-bottom: 40px;
}

/* style={{ display: "flex", alignItems: "center" }} */
.notification.grid-1 {
    display: flex;
    align-items: center;
}

/* style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: "100%", }} */
.notification.card-1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
}
.notification.card-1.active{
    background: #f3f4f6;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 9px 0px;
    border: 2px solid #6c777d;
    padding: 25px;
}
.notification btn-4{
    background: #1790bc;
    color: #fff;
    padding: 12px 20px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    display: block;
    border: none;
    font-size: 15px;
}
.preview-img{
    height:400px !important;
}