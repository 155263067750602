/* #input[type="file"] {
  display: none;
} */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px !important
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%
}
.new-pswd{
  display:flex;
}
.otptext input{
 width:40px !important;
 height:50px !important;
  border-radius:5px !important;
  margin:5px !important;
}
.phone-input-new input{
  border:none;
  outline: none;
}
.forget-pass-sendotp{
  width: 500px;
   margin: 0 auto;
    display: flex;
  flex-direction: column;
  align-items: center;
}
.phone-input-new {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 5px;
}
.msg-box {
  text-align: center;
  margin-top: 25px;
  width: 75%;
}

.css-p0rm37 {
  top: -6px !important;
}

.css-i44wyl,
.css-17vdz66 {
  width: 100% !important;
}
.new-pswd label{
  white-space: nowrap;
  margin: 10px;
  width: 220px;
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1bn53lx {
  border: 1px solid #ced4da !important;
}

.css-1uvydh2 {
  padding: 8.5px 14px !important;
}

textarea.form-control {
  height: 38px !important;
}

.css-1x5jdmq {
  padding: 7.5px 14px !important;
}
.phone-input input, .phone-input .phoneInput--focus{
border: none;
}

.rs-picker-toggle-wrapper {
  display: flex !important;
  border: 1px solid#cccccc !important;
  border-radius: 5px !important;

}

td.MuiTableCell-root.MuiTableCell-body .innercell {
  display: flex !important;
  align-items: center !important;



}

.password-btn {
  border: none;
  background: none;
}

.modal-passwd {
  display: flex;
  flex-direction: column;
}

.modal-input {
  border: 2px solid grey;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.modal-submit {
  width: 40%;
  border: none;
  background: #1790bc;
  color: #fff;
  padding: 8px;
  font-size: 21px;
  border-radius: 5px;
}

.modal-passwd h3 {
  font-size: 25px;
  color: #000 !important;
}


.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  /* color: #d32f2f; */
  color: rgba(0, 0, 0, 0.6) !important;
}


.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ccc !important;
}

.Mui-error {
  color: #495057 !important;

}

.MuiInputBase-colorPrimary {
  color: #495057 !important;
}

.remove-border {
  border-color: #ccc !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ccc !important;
  border-width: 2px !important;
  color: #495057 !important;
}

.card-area {
  width: 160px;
}

.card-area h1,
.card-area p,
.card-area a {
  color: #1790bc !important;
}

.dashboard-row img {
  width: 60px;
}

.dashboard-row {
  box-shadow: 0px 0px 5px 3px #eee !important;
  justify-content: space-around;
  color: #1790bc !important;
  margin: 20px 20px !important;
}

.add-mobile .author-input {
  margin: 0 12px 0 5px !important;
}

.rs-picker-toggle.rs-btn.rs-btn-default {
  z-index: 0 !important;
}

img {
  height: auto !important;
}
.form-control:disabled, .form-control[readonly]{
  background-color: #e9ecef !important;
}
.modal-body.popup-offer {
  padding: 7px 16px;
}
.modal-footer.offer-ftxt {
  border-top: none;
}
.modal-footer.offer-ftxt button {
    padding: 9px 44px;
    font-size: 18px;
}
.type-link{
  position: relative;
  z-index: 9;
}
.desc-btn{
  color: #007bff;
  background-color: white
}
.row-sec {
  /* width: 84%; */
  display: inline-flex !important;
}
.row-sec-author-log{

  display: inline-flex !important;
}
.row-sec-author{
  display: inline-flex !important;
}

.bg-new {
    background: #F1F5F8;
    border-radius: 9px;
}
.row-secth {
  padding: 0px;
  border: none;
}

.border-section {
  border-left: 6px solid #1790BC;
  height:100%;
  position: relative;
}
.border-section:before {
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  content: "";
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid #1790BC;
}
tr.MuiTableRow-root.row-sec.bg-new.MuiTableRow-head {
  margin-bottom: 24px;
  padding: 11px;
}
/* .row-sec {
  width: 70%;
  display: inline-flex !important;
} */

.with-button-logactivity{
  display: flex !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.border-section-with-switch-author{
  border-left: 6px solid #1790BC;
  height:84px;
  position: relative;
}
.border-section-with-switch-author:before{
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  content: "";
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid #1790BC;
}

tr.MuiTableRow-root.row-sec-author.bg-new.MuiTableRow-head {
  margin-bottom: 24px;
  padding: 11px;
}
.row-sec-author th {
  padding: 0px;
  border: none;
}
.row-sec-author{
  display: inline-flex !important;
}