/* THIS IS CSS OF LOADER */
.app-loader-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  background-color: #cccccc57;
  z-index: 99999;
}
.app-loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3b6fd7; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.modal-heading-wrapper {
  padding: 25px 45px;
  background-color: #3673fc;
  color: #fff;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
